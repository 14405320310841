.work__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.work__item .item > img {
  width: 100%; /* Make image responsive to the container's width */
  max-width: 200px; /* Maximum width of the image */
  height: auto; /* Maintain aspect ratio */
}

.work__item h2 {
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}

.work__item .item {
  width: 330px; /* Adjust as necessary to fit your layout */
  padding: 20px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  
}

.work__item .item > .item__tags {
  display: flex; /* Use flexbox to manage tags */
  flex-wrap: wrap; /* Allow tags to wrap to the next line if not enough space */
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 10px; /* Space between tags */
}

.work__item .item > .item__tags > .tags {
  word-break: keep-all; /* Prevent splitting words in tags */
  white-space: nowrap; /* Keep tag text on one line */
  background: rgb(247, 205, 205);
  color: rgb(64, 64, 64);
  padding: 2px 10px;
  font-size: 12px;
}

.quick__intro > p {
  padding-bottom: 40px;
}



.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.w-100{
  width: 90%;
}

.pt-2{
  padding-top: 20px;
}