.hero{
    position: relative;
    overflow: hidden;
    background-color: #111418 ;
}

.hero:after{
    content: "";
    width: 100%;
    height:100%;
    background-color: green;
}

.hero__innerText{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    color: white;    
    justify-content: center;
}

.hero__innerText > p{
    font-weight: 400;
    font-size: 2rem;
}
.hero__innerText > h1{
    font-weight: 600;
    font-size: 5rem;
}
