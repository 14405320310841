@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700&display=swap");

.app {
  margin-left: 0 !important;
}

img {
  width: 100%;
}

.navbar {
  padding: 5px 0;
}

.navbar a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.navbar a:hover {
  text-decoration: underline;
}
.navbar a img {
  border-radius: 50px;
  width: 30px;
  border: 2px solid purple;
  margin-right: 10px;
}

h3.large {
  font-size: 80px !important;
}

p.large {
  font-size: 30px !important;
}

.container-full {
  padding: 0 30px;
}

.container {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  min-height: 30px;
}

.container--sm {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
  min-height: 30px;
}

.spacer--sm {
  margin-top: 35px;
  margin-bottom: 35px;
  display: block;
}
.spacer {
  margin-top: 70px;
  margin-bottom: 70px;
  display: block;
}

.storeLocator {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.align--center {
  text-align: center;
}

.storeLocator h3 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #353738;
}

.storeLocator p {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  color: #353738;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.page__banner {
  width: 100%;
  height: 680px;
  background: rgb(132, 75, 162);
  background: linear-gradient(
    352deg,
    rgba(132, 75, 162, 1) 0%,
    rgba(88, 0, 181, 1) 100%
  );
  position: relative;
  margin-bottom: 100px;
}

.page__banner > .banner__title {
  color: white !important;
  position: absolute;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.7);
}

.page__banner > h1,
.page__banner > h3 {
  left: 200px;
}

.page__banner > h1 {
  bottom: 150px;
  font-size: 88px;
  font-weight: 800;
}
.page__banner > h3 {
  bottom: 80px;
  font-size: 22px;
  font-weight: 400;
}

.flex--2,
.flex--4 {
  display: flex;
  justify-content: space-between;
}

.flex--2 > div {
  flex: 0.49;
  min-height: 30px;
}

ul {
  padding-left: 36px;
  color: #353738;
}

ul > li {
  line-height: 30px;
  font-size: 18px;
}
