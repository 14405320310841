.about__wrapper > .about__content{
    display: flex;    
}

.about__content > .about__contentLeft{
    flex: .7;    
}


.about__content > .about__contentLeft > h2{
    color: #252b33;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 16px;
    line-height: 33px;
}


.about__content > .about__contentLeft > p{
    color: #252b33;
    font-size: 16px;
    padding-bottom: 16px;
    line-height: 28.8px;
}



.about__content > .about__contentRight{
    flex: .3;  
    color: #4c4d4d;  
    text-align: center;
}


.about__content > .about__contentRight > p{
    font-size:16px; 
    padding: 12px 0;    
    border-bottom: 1px solid #eaeaea;
    font-weight: 400;
    margin-left: 20px;
    text-align: left;
}

.about__content > .about__contentRight > p:nth-child(4){
    border-bottom:0;
}


.about__contentRight > p > a{
    color: #20c997;
    text-decoration: none;
}

.software{
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.software img{
    width: 90px;
    
}