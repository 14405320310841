.content {
  position: absolute;
  top: 0;
  right: 0;
  left: 261px;
  bottom: 0;
}

.contact > .contact__wrapper,
.portfolio > .portfolio__wrapper,
.resume > .resume__wrapper,
.services > .services__wrapper,
.about > .about__wrapper {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 72px;
  padding-bottom: 72px;
}

.contact__wrapper > .contact__top,
.portfolio__wrapper > .portfolio__top,
.resume__wrapper > .resume__top,
.services__wrapper > .services__top,
.about__wrapper > .about__top {
  position: relative;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-bottom: 48px;
  height: 120px;
}
.contact__wrapper > .contact__top > h2,
.portfolio__wrapper > .portfolio__top > h2,
.resume__wrapper > .resume__top > h2,
.services__wrapper > .services__top > h2,
.about__wrapper > .about__top > h2 {
  font-weight: 600;
  font-size: 132px;
  position: absolute;
  color: #dee3e4;
  opacity: 0.7;
  left: 270px;
  top: -75px;
  text-transform: uppercase;
}

.contact__wrapper > .contact__top > p,
.portfolio__wrapper > .portfolio__top > p,
.resume__wrapper > .resume__top > p,
.services__wrapper > .services__top > p,
.about__wrapper > .about__top > p {
  font-weight: 600;
  font-size: 36px;
  color: #343a40;
  position: relative;
  z-index: 1;
}

.buttonContactMe,
.buttonCV {
  background-color: #20c997 !important;
  border: 1px solid #20c997 !important;
  padding: 0.8rem 2.6rem !important;
  font-weight: 500 !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: white !important;
  outline: 0 !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  margin-top: 32px !important;
  cursor: pointer !important;
  text-decoration: none;
}

.portfolio,
.services {
  background-color: #f8f9fa;
  display: flex;
}

@media (max-width: 500px) {
  .nav {
    border: none;
  }
  .nav.nav--active {
    width: 0px;
  }

  .content {
    left: 0;
    padding: 0 10px;
  }

  .work__item .item:nth-child(1),
  .work__item .item:nth-child(2),
  .work__item .item {
    width: 355px !important;
  }

  .contact > .contact__wrapper,
  .portfolio > .portfolio__wrapper,
  .resume > .resume__wrapper,
  .services > .services__wrapper,
  .about > .about__wrapper {
    width: 100% !important;
    height: auto !important;
  }

  .contact__wrapper > .contact__top > h2,
  .portfolio__wrapper > .portfolio__top > h2,
  .resume__wrapper > .resume__top > h2,
  .services__wrapper > .services__top > h2,
  .about__wrapper > .about__top > h2 {
    font-size: 57px !important;
    left: 10px;
    top: -25px;
  }

  .about__wrapper > .about__content,
  .contact__content {
    flex-direction: column;
  }

  .contact__form {
    width: 450px;
  }

  .hero__innerText > h1 {
    font-weight: 600;
    font-size: 2rem !important;
  }

  .hero__innerText > p {
    font-weight: 400;
    font-size: 1rem !important;
  }

  .burgerMenu {
    display: block !important;
  }

  .flex--2,
  .flex--4 {
    display: flex;
    flex-direction: column !important;
  }

  .container--sm,
  .container div {
    padding: 0 10px;
  }

  .page__banner > h1 {
    font-size: 36px;
    bottom: 540px;
  }

  .page__banner > h3 {
    font-size: 16px;
    bottom: 470px;
  }

  .page__banner {
    background-position: right center;
  }

  .page__banner > h1,
  .page__banner > h3 {
    left: 50px !important;
    right: 50px !important;
  }
}
