.header{
    top: 0;
    z-index: 1020;
    height: 100vh;
}

.nav{
    position: fixed;
    left: 270;
    top: 0;
    width: 260px;
    height: 100%;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
    z-index: 2;
    overflow: hidden;
    overflow-y: auto;
    background-color: #111418;
    display: flex;
    align-items: center;
    flex-direction: column;    
    transition: all 0.3s ease;
}

.nav__profile{
    width: 200px;
    height: 200px;
    border-radius: 150px;
    margin-top: 40px;
    margin-bottom: 20px;
    border: 10px solid #4c4d4d;
}

.nav > h1{
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
}

.nav__links{
    padding-top: 97px;
    padding-bottom: 97px;
    display: flex;
    flex-direction: column;
}

.nav__links > .button{
    margin-bottom: 20px;
    background-color: transparent;
    outline: transparent;
    border: none;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    text-decoration: none;
}
.nav__social > a > .MuiSvgIcon-root:hover,
.nav__links > .button:hover,
.nav__links > .button.active{
    color: #20c997;
}

.nav__social{
    position: absolute;
    bottom: 20px;
    display: flex;
}

.nav__social > a{
    color: white;
}

.nav__social > a > .MuiSvgIcon-root{
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}


.burgerMenu{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    display: none;
}


.burgerMenu > .burgerMenu--show{
    color: white;
}

