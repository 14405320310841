@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,700&display=swap);
*{
  margin: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body::-webkit-scrollbar {
    width: 0.4em;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #20c997;
  outline: 1px solid #20c997;
}
.app{
    margin-left: 260px;
}


.header{
    top: 0;
    z-index: 1020;
    height: 100vh;
}

.nav{
    position: fixed;
    left: 270;
    top: 0;
    width: 260px;
    height: 100%;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
    z-index: 2;
    overflow: hidden;
    overflow-y: auto;
    background-color: #111418;
    display: flex;
    align-items: center;
    flex-direction: column;    
    transition: all 0.3s ease;
}

.nav__profile{
    width: 200px;
    height: 200px;
    border-radius: 150px;
    margin-top: 40px;
    margin-bottom: 20px;
    border: 10px solid #4c4d4d;
}

.nav > h1{
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
}

.nav__links{
    padding-top: 97px;
    padding-bottom: 97px;
    display: flex;
    flex-direction: column;
}

.nav__links > .button{
    margin-bottom: 20px;
    background-color: transparent;
    outline: transparent;
    border: none;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    text-decoration: none;
}
.nav__social > a > .MuiSvgIcon-root:hover,
.nav__links > .button:hover,
.nav__links > .button.active{
    color: #20c997;
}

.nav__social{
    position: absolute;
    bottom: 20px;
    display: flex;
}

.nav__social > a{
    color: white;
}

.nav__social > a > .MuiSvgIcon-root{
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}


.burgerMenu{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    display: none;
}


.burgerMenu > .burgerMenu--show{
    color: white;
}


.about__wrapper > .about__content{
    display: flex;    
}

.about__content > .about__contentLeft{
    flex: .7 1;    
}


.about__content > .about__contentLeft > h2{
    color: #252b33;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 16px;
    line-height: 33px;
}


.about__content > .about__contentLeft > p{
    color: #252b33;
    font-size: 16px;
    padding-bottom: 16px;
    line-height: 28.8px;
}



.about__content > .about__contentRight{
    flex: .3 1;  
    color: #4c4d4d;  
    text-align: center;
}


.about__content > .about__contentRight > p{
    font-size:16px; 
    padding: 12px 0;    
    border-bottom: 1px solid #eaeaea;
    font-weight: 400;
    margin-left: 20px;
    text-align: left;
}

.about__content > .about__contentRight > p:nth-child(4){
    border-bottom:0;
}


.about__contentRight > p > a{
    color: #20c997;
    text-decoration: none;
}

.software{
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.software img{
    width: 90px;
    
}
.content {
  position: absolute;
  top: 0;
  right: 0;
  left: 261px;
  bottom: 0;
}

.contact > .contact__wrapper,
.portfolio > .portfolio__wrapper,
.resume > .resume__wrapper,
.services > .services__wrapper,
.about > .about__wrapper {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 72px;
  padding-bottom: 72px;
}

.contact__wrapper > .contact__top,
.portfolio__wrapper > .portfolio__top,
.resume__wrapper > .resume__top,
.services__wrapper > .services__top,
.about__wrapper > .about__top {
  position: relative;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-bottom: 48px;
  height: 120px;
}
.contact__wrapper > .contact__top > h2,
.portfolio__wrapper > .portfolio__top > h2,
.resume__wrapper > .resume__top > h2,
.services__wrapper > .services__top > h2,
.about__wrapper > .about__top > h2 {
  font-weight: 600;
  font-size: 132px;
  position: absolute;
  color: #dee3e4;
  opacity: 0.7;
  left: 270px;
  top: -75px;
  text-transform: uppercase;
}

.contact__wrapper > .contact__top > p,
.portfolio__wrapper > .portfolio__top > p,
.resume__wrapper > .resume__top > p,
.services__wrapper > .services__top > p,
.about__wrapper > .about__top > p {
  font-weight: 600;
  font-size: 36px;
  color: #343a40;
  position: relative;
  z-index: 1;
}

.buttonContactMe,
.buttonCV {
  background-color: #20c997 !important;
  border: 1px solid #20c997 !important;
  padding: 0.8rem 2.6rem !important;
  font-weight: 500 !important;
  transition: all 0.5s ease;
  color: white !important;
  outline: 0 !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  margin-top: 32px !important;
  cursor: pointer !important;
  text-decoration: none;
}

.portfolio,
.services {
  background-color: #f8f9fa;
  display: flex;
}

@media (max-width: 500px) {
  .nav {
    border: none;
  }
  .nav.nav--active {
    width: 0px;
  }

  .content {
    left: 0;
    padding: 0 10px;
  }

  .work__item .item:nth-child(1),
  .work__item .item:nth-child(2),
  .work__item .item {
    width: 355px !important;
  }

  .contact > .contact__wrapper,
  .portfolio > .portfolio__wrapper,
  .resume > .resume__wrapper,
  .services > .services__wrapper,
  .about > .about__wrapper {
    width: 100% !important;
    height: auto !important;
  }

  .contact__wrapper > .contact__top > h2,
  .portfolio__wrapper > .portfolio__top > h2,
  .resume__wrapper > .resume__top > h2,
  .services__wrapper > .services__top > h2,
  .about__wrapper > .about__top > h2 {
    font-size: 57px !important;
    left: 10px;
    top: -25px;
  }

  .about__wrapper > .about__content,
  .contact__content {
    flex-direction: column;
  }

  .contact__form {
    width: 450px;
  }

  .hero__innerText > h1 {
    font-weight: 600;
    font-size: 2rem !important;
  }

  .hero__innerText > p {
    font-weight: 400;
    font-size: 1rem !important;
  }

  .burgerMenu {
    display: block !important;
  }

  .flex--2,
  .flex--4 {
    display: flex;
    flex-direction: column !important;
  }

  .container--sm,
  .container div {
    padding: 0 10px;
  }

  .page__banner > h1 {
    font-size: 36px;
    bottom: 540px;
  }

  .page__banner > h3 {
    font-size: 16px;
    bottom: 470px;
  }

  .page__banner {
    background-position: right center;
  }

  .page__banner > h1,
  .page__banner > h3 {
    left: 50px !important;
    right: 50px !important;
  }
}

.hero{
    position: relative;
    overflow: hidden;
    background-color: #111418 ;
}

.hero:after{
    content: "";
    width: 100%;
    height:100%;
    background-color: green;
}

.hero__innerText{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    color: white;    
    justify-content: center;
}

.hero__innerText > p{
    font-weight: 400;
    font-size: 2rem;
}
.hero__innerText > h1{
    font-weight: 600;
    font-size: 5rem;
}


.work__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.work__item .item > img {
  width: 100%; /* Make image responsive to the container's width */
  max-width: 200px; /* Maximum width of the image */
  height: auto; /* Maintain aspect ratio */
}

.work__item h2 {
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}

.work__item .item {
  width: 330px; /* Adjust as necessary to fit your layout */
  padding: 20px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  
}

.work__item .item > .item__tags {
  display: flex; /* Use flexbox to manage tags */
  flex-wrap: wrap; /* Allow tags to wrap to the next line if not enough space */
  margin-top: 10px;
  margin-bottom: 20px;
  grid-gap: 10px;
  gap: 10px; /* Space between tags */
}

.work__item .item > .item__tags > .tags {
  word-break: keep-all; /* Prevent splitting words in tags */
  white-space: nowrap; /* Keep tag text on one line */
  background: rgb(247, 205, 205);
  color: rgb(64, 64, 64);
  padding: 2px 10px;
  font-size: 12px;
}

.quick__intro > p {
  padding-bottom: 40px;
}



.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.w-100{
  width: 90%;
}

.pt-2{
  padding-top: 20px;
}
.app {
  margin-left: 0 !important;
}

img {
  width: 100%;
}

.navbar {
  padding: 5px 0;
}

.navbar a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.navbar a:hover {
  text-decoration: underline;
}
.navbar a img {
  border-radius: 50px;
  width: 30px;
  border: 2px solid purple;
  margin-right: 10px;
}

h3.large {
  font-size: 80px !important;
}

p.large {
  font-size: 30px !important;
}

.container-full {
  padding: 0 30px;
}

.container {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  min-height: 30px;
}

.container--sm {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
  min-height: 30px;
}

.spacer--sm {
  margin-top: 35px;
  margin-bottom: 35px;
  display: block;
}
.spacer {
  margin-top: 70px;
  margin-bottom: 70px;
  display: block;
}

.storeLocator {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.align--center {
  text-align: center;
}

.storeLocator h3 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #353738;
}

.storeLocator p {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  color: #353738;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.page__banner {
  width: 100%;
  height: 680px;
  background: rgb(132, 75, 162);
  background: linear-gradient(
    352deg,
    rgba(132, 75, 162, 1) 0%,
    rgba(88, 0, 181, 1) 100%
  );
  position: relative;
  margin-bottom: 100px;
}

.page__banner > .banner__title {
  color: white !important;
  position: absolute;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.7);
}

.page__banner > h1,
.page__banner > h3 {
  left: 200px;
}

.page__banner > h1 {
  bottom: 150px;
  font-size: 88px;
  font-weight: 800;
}
.page__banner > h3 {
  bottom: 80px;
  font-size: 22px;
  font-weight: 400;
}

.flex--2,
.flex--4 {
  display: flex;
  justify-content: space-between;
}

.flex--2 > div {
  flex: 0.49 1;
  min-height: 30px;
}

ul {
  padding-left: 36px;
  color: #353738;
}

ul > li {
  line-height: 30px;
  font-size: 18px;
}

.app {
  margin-left: 0 !important;
}

img {
  width: 100%;
}

.navbar {
  padding: 5px 0;
}

.navbar a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.navbar a:hover {
  text-decoration: underline;
}
.navbar a img {
  border-radius: 50px;
  width: 30px;
  border: 2px solid purple;
  margin-right: 10px;
}

h3.large {
  font-size: 80px !important;
}

p.large {
  font-size: 30px !important;
}

.container-full {
  padding: 0 30px;
}

.container {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  min-height: 30px;
}

.container--sm {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
  min-height: 30px;
}

.spacer--sm {
  margin-top: 35px;
  margin-bottom: 35px;
  display: block;
}
.spacer {
  margin-top: 70px;
  margin-bottom: 70px;
  display: block;
}

.Byuidaho {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.align--center {
  text-align: center;
}

.Byuidaho h3 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #353738;
}

.Byuidaho p {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  color: #353738;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.page__banner {
  width: 100%;
  height: 680px;
  background: rgb(132, 75, 162);
  background: linear-gradient(
    352deg,
    rgba(132, 75, 162, 1) 0%,
    rgba(88, 0, 181, 1) 100%
  );
  position: relative;
  margin-bottom: 100px;
}

.page__banner > .banner__title {
  color: black;
  position: absolute;
}

.page__banner > h1,
.page__banner > h3 {
  left: 200px;
}

.page__banner > h1 {
  bottom: 150px;
  font-size: 88px;
  font-weight: 800;
}
.page__banner > h3 {
  bottom: 80px;
  font-size: 22px;
  font-weight: 400;
}

.flex--2,
.flex--3,
.flex--4 {
  display: flex;
  justify-content: space-between;
}

.flex--2 > div {
  flex: 0.49 1;
  min-height: 30px;
}

.flex--3 > div {
  flex: 0.33 1;
  min-height: 30px;
}

.flex--4 > div {
  flex: 0.25 1;
  min-height: 30px;
}

ul {
  padding-left: 36px;
  color: #353738;
}

ul > li {
  line-height: 30px;
  font-size: 18px;
}

.quote {
  font-size: 20px;
  font-weight: 400;
  background: #f9f9f9;
  border-left: 2px solid black;
  padding-left: 30px;
  font-style: italic;
  padding-top: 1em;
  padding-bottom: 1em;
}

.quote p {
  font-size: inherit;
  padding: 0;
}

.app {
  margin-left: 0 !important;
}

img {
  width: 100%;
}

.navbar {
  padding: 5px 0;
}

.navbar a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.navbar a:hover {
  text-decoration: underline;
}
.navbar a img {
  border-radius: 50px;
  width: 30px;
  border: 2px solid purple;
  margin-right: 10px;
}

h3.large {
  font-size: 80px !important;
}

p.large {
  font-size: 30px !important;
}

.container-full {
  padding: 0 30px;
}

.container {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  min-height: 30px;
}

.container--sm {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
  min-height: 30px;
}

.spacer--sm {
  margin-top: 35px;
  margin-bottom: 35px;
  display: block;
}
.spacer {
  margin-top: 70px;
  margin-bottom: 70px;
  display: block;
}

.airbnb {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.align--center {
  text-align: center;
}

.airbnb h3 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #353738;
}

.airbnb p {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  color: #353738;
}



.airbnb ul > ol {
  padding:0;
  margin: 0;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.page__banner {
  width: 100%;
  height: 680px;
  background: rgb(132, 75, 162);
  background: linear-gradient(
    352deg,
    rgba(132, 75, 162, 1) 0%,
    rgba(88, 0, 181, 1) 100%
  );
  position: relative;
  margin-bottom: 100px;
}

.page__banner > .banner__title {
  color: white !important;
  position: absolute;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.7);
}

.page__banner > h1,
.page__banner > h3 {
  left: 200px;
}

.page__banner > h1 {
  bottom: 150px;
  font-size: 88px;
  font-weight: 800;
}
.page__banner > h3 {
  bottom: 80px;
  font-size: 22px;
  font-weight: 400;
}

.flex--2,
.flex--4 {
  display: flex;
  justify-content: space-between;
}

.flex--2 > div {
  flex: 0.49 1;
  min-height: 30px;
}

ul {
  padding-left: 36px;
  color: #353738;
}

ul > li {
  line-height: 30px;
  font-size: 18px;
}

.text-sm {  
  font-size: 20px;
  position: relative;
  top: -50px;
}


